import React from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';

export default function index() {
    return (
        <div>
            <Header />
            <div className='container'>
                <div className="row">
                    <div className="col">
                        <h3 className='mt-3'>Users</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">

                                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default " to="/user/create"><Icon name="add" color="white" /> New</Link>
                        </div>

                        <DataTable column={[
                        
                            { label: "Name", field: "name" },
                            { label: "Email", field: "email" },
                            { label: "Role", field: "roles" }
                        ]}

                            element={"user"}
                            resource={resources.user}
                            action={(data) => {

                            }}
                            deleteItem
                            update
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
