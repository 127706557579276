import React from "react";
import PropTypes from "prop-types";

function Button(props) {
    const {onLoad, children} = props;
    return (
        <>
            {onLoad ? (
                <button {...props} disabled>
          <span
              className="spinner-border spinner-border-sm mr-1"
              role="status"
              aria-hidden="true"
          />
                    {children}
                </button>
            ) : (
                <button {...props}>{children}</button>
            )}
        </>
    );
}


export default Button;
