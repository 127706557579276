import Axios from "../Axios";

const company = {
    read(token: string) {
        return Axios.get('/companies', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    readOne(id: string) {
        return Axios.get('/companies/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object, token: string) {
        return Axios.post('/companies', data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    },
    update(id: string, data: object) {
        return Axios.put('/companies/' + id, data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/companies/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },


}

export default company;
