import Axios from "../Axios";

const bin = {
    read(token: string) {
        return Axios.get('/bins', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    readOne(id: string) {
        return Axios.get('/bins/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object, token: string) {
        return Axios.post('/bins', data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    },
    update(id: string, data: object) {
        return Axios.put('/bins/' + id, data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/bins/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },


}

export default bin;
