import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import Button from "../../ui/Button";
import resources from '../../api';
import Select from 'react-select'

export default function Create() {
    const [loader, setLoader] = useState(false)
    const [companies, setCompanies] = useState([])
    const [groups, setGroups] = useState([])
    const [roles, setRole] = useState()

    const role = [
        { value: "STAFF", label: "Staff" },
        { value: "CLIENT", label: "Client" },
    ]
    async function onGet() {
        let cus = await resources.company.read(10000)
        if (cus.status === 200) {
            let a = []
            cus.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompanies(a)
        }


        let gr = await resources.group.read(10000)
        if (gr.status === 200) {
            let a = []
            gr.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.title,
                })
            })
            setGroups(a)
        }
    }

    useEffect(() => {
        onGet();
    }, [])

    function create(e) {
        e.preventDefault()
        // setLoader(true)
        let formData = new FormData(e.target)
        formData.append("password_confirmation", e.target.password.value)
        let res = resources.user.create(formData, localStorage.getItem("userToken"))
        if (res) {
            alert("Succes")
            setLoader(false)
        }
        setLoader(false)


    }
    return (
        <div>
            <Header />
            <div className='container'>
                <div className="row">
                    <div className="col">
                        <h3 className='mt-3'>User</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page"><Link
                                        className="text-muted" to="/users">Users</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">new user</li>
                                </ol>
                            </nav>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <div className='card'>

                                    <div className='card-body'>
                                        <form onSubmit={create}>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Full name <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="text" required className="form-control" id="exampleInputEmail1"
                                                    name="name" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Email <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="email" required className="form-control" id="exampleInputEmail1"
                                                    name="email" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Password <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="password" required className="form-control" id="exampleInputEmail1"
                                                    name="password" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Role <span
                                                        style={{ color: "red" }}>*</span></label>
                                                <Select name="roles" options={role} onChange={(e) => setRole(e.value)} />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Groupe <span
                                                        style={{ color: "red" }}>*</span></label>
                                                <Select name="group" options={groups} />
                                            </div>

                                            {
                                                roles === "CLIENT" && <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                        className="form-label">Companies <span
                                                            style={{ color: "red" }}>*</span></label>
                                                    <Select required name='company' options={companies} />
                                                </div>
                                            }

                                            <Button type="submit" onLoad={loader} className="btn btn-app-default w-100">Save</Button>
                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
