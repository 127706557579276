import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import Select from 'react-select'
import Button from "../../ui/Button";


export default function Material() {

    const [filter, setFilter] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])



    async function onGet() {

        let com = await resources.company.read(10000)
        if (com.status === 200) {
            let a = []
            com.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompanies(a)
        }

        let bin = await resources.bin.read(10000)
        if (bin.status === 200) {
            let a = []
            bin.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setbins(a)
        }

        let stor = await resources.storage.read(10000)
        if (stor.status === 200) {
            let a = []
            stor.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setStorages(a)
        }

        let typ = await resources.type.read(10000)
        if (typ.status === 200) {
            let a = []
            typ.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTypes(a)
        }

        let sub = await resources.subtype.read(10000)
        if (sub.status === 200) {
            let a = []
            sub.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setSubtypes(a)
        }


    }

    useEffect(() => {
        onGet();
    }, [])

    function filerArticle(e) {
        e.preventDefault();
        setFilter(true)
        let formData = new FormData(e.target);
        let el = '';
        if (formData.get('name')) {
            el = "&name=" + formData.get('name')
        }
        if (formData.get('id')) {
            el += "&id=" + formData.get('id')
        }

        if (formData.get('categorie')) {
            el += "&category=" + formData.get('categorie')
        }
        if (formData.get('quantity')) {
            el += "&quantity=" + formData.get('quantity')
        }

        window.location.replace("/materials?" + el)

    }

    function onChangeFilterLoader() {
        setFilter(false)
    }

    return (
        <div>
            <Header />
            <div className='container'>
                <div className="row">
                    <div className="col">
                        <h3 className='mt-3'>Materials</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">

                                    <li className="breadcrumb-item active" aria-current="page">Materials</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default " to="/material/create"><Icon name="add" color="white" /> New</Link>
                        </div>

                        <div className="card card-body mb-3 mt-4">
                            <form onSubmit={filerArticle}>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="ID" name="id" />
                                    </div>
                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" name="name" />
                                    </div>

                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Stock" name="price" />
                                    </div>
                                    <div className="col">
                                        <Select name="type" placeholder="Type" options={types} />
                                    </div>

                                    <div className="col">
                                        <Button onLoad={filter} className="btn btn-app-default w-100" type="submit"> Apply</Button>
                                    </div>

                                </div>

                            </form>

                        </div>

                        <DataTable column={[
                            {
                                label: "Cover ", field: "picture", renderStyle: (data) => {
                                    return <img src={config.server+"/files/" + data.cover} alt={data.name} width="80" />
                                }
                            },
                            { label: "ID ", field: "unique_number" },
                            { label: "Company ", field: "company", render: ["name"]  },
                            { label: "Name", field: "name" },
                            { label: "Stock count", field: "stock_on_hand" },
                            { label: "Type", field: "type", render: ["name"] },
                            { label: "Sub-type", field: "sub_type", render: ["name"] },
                            { label: "Storage location", field: "storage", render: ["name"] },
                            { label: "Bin location", field: "bin", render: ["name"] },
                            { label: "Where used", field: "where_used" },
                            { label: "Set size", field: "set_size" },
                            { label: "OEM", field: "oem" },
                            { label: "Last purchased from", field: "last_purshased_from" },
                            { label: "Last purchased date", field: "last_purshased_date" },
                            { label: "Material part number", field: "part_number" },
                            { label: "Description", field: "description" },
                        ]}


                            filterLoader={onChangeFilterLoader}
                            element={"material"}
                            resource={resources.material}
                            action={(data) => {

                            }}
                            update
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
