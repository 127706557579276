import React from 'react'
import logo from "../assets/picco.png"
import Icon from '../ui/Icon';
import {
    Link
} from "react-router-dom";

export default function Header() {
    return (
        <header>
            <div className="px-3 py-2 text-bg-dark">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
                            <img src={logo} width="100" alt="PICCO" />
                        </a>

                        <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                            <li>
                                <Link to="/materials" className="nav-link text-white">
                                    <Icon className="bi d-block mx-auto mb-1" name={"star"} color="white" />
                                    Material
                                </Link>
                            </li>
                            <li>
                                <Link to="/companies" className="nav-link text-white">
                                    <Icon className="bi d-block mx-auto mb-1" name={"shop"} color="white" />
                                    Company
                                </Link>
                            </li>
                            <li>
                                <Link to="/users" className="nav-link text-white">
                                    <Icon className="bi d-block mx-auto mb-1" name={"person"} color="white" />
                                    Users
                                </Link>
                            </li>
                            <li>
                                <Link to="/report" className="nav-link text-white">
                                    <Icon className="bi d-block mx-auto mb-1" name={"spreadsheet"} color="white" />
                                    Report
                                </Link>
                            </li>
                            <li>
                                <Link href="#" className="nav-link text-white">
                                    <Icon className="bi d-block mx-auto mb-1" name={"gear"} color="white" />
                                    Options
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}
