import auth from "./auth"
import material from "./material"
import company from "./company"
import bin from "./bin"
import storage from "./storage"
import subtype from "./subtype"
import user from "./user"
import group from "./group"
import type from "./type"



const resources = {
    auth,
    material,
    company,
    bin,
    storage,
    subtype,
    type,
    user,
    group
};
export default resources;
