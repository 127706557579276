import Axios from "../Axios";

const group = {
    read(token: string) {
        return Axios.get('/groupes', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    readOne(id: string) {
        return Axios.get('/groups/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object, token: string) {
        return Axios.post('/groups', data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    },
    update(id: string, data: object) {
        return Axios.put('/groups/' + id, data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/groups/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },


}

export default group;
