import React, { useState } from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import Button from "../../ui/Button";
import resources from '../../api';

export default function Create() {
    const [loader, setLoader] = useState(false)

    function create(e) {
        e.preventDefault()
        setLoader(true)
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            let res = resources.company.create({
                name: e.target.name.value,
                logo: reader.result,
                description: e.target.description.value,

            }, localStorage.getItem("userToken"))

            if (res) {
                setLoader(false)
            }

        })
        reader.readAsDataURL(e.target.img.files[0])
        setLoader(false)


    }
    return (
        <div>
            <Header />
            <div className='container'>
                <div className="row">
                    <div className="col">
                        <h3 className='mt-3'>Company</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page"><Link
                                        className="text-muted" to="/compagnies">Companies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">new compagny</li>
                                </ol>
                            </nav>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <div className='card'>

                                    <div className='card-body'>
                                        <form onSubmit={create}>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Logo <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="file" required className="form-control" id="exampleInputEmail1"
                                                    name="img" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Name <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="text" required className="form-control" id="exampleInputEmail1"
                                                    name="name" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Description <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="text" className="form-control"  id="exampleInputEmail1"
                                                    name="description" />
                                            </div>
                                            <Button type="submit" onLoad={loader} className="btn btn-app-default w-100">Save</Button>
                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
