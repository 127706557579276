import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import Login from "./pages/Login"
import Material from "./pages/Material"
import MaterialCreate from "./pages/Material/Create"
import MaterialEdit from "./pages/Material/Edit"

import Company from "./pages/Company"
import CompanyCreate from "./pages/Company/Create"
import CompanyEdit from "./pages/Company/Edit"

import Bin from "./pages/Bin"
import BinCreate from "./pages/Bin/Create"
import BinEdit from "./pages/Bin/Edit"

import Storage from "./pages/Storage"
import StorageCreate from "./pages/Storage/Create"
import StorageEdit from "./pages/Storage/Edit"

import Subtype from "./pages/Subtype"
import SubtypeCreate from "./pages/Subtype/Create"
import SubtypeEdit from "./pages/Subtype/Edit"

import Type from "./pages/Type"
import TypeCreate from "./pages/Type/Create"
import TypeEdit from "./pages/Type/Edit"

import User from "./pages/User"
import UserCreate from "./pages/User/Create"
import UserEdit from "./pages/User/Edit"

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />

        <Route path='/materials' element={<Material />} />
        <Route path='/material/create' element={<MaterialCreate />} />
        <Route path='/material/edit/:id' element={<MaterialEdit />} />

        <Route path='/companies' element={<Company />} />
        <Route path='/company/create' element={<CompanyCreate />} />
        <Route path='/company/edit/:id' element={<CompanyEdit />} />

        <Route path='/users' element={<User />} />
        <Route path='/user/create' element={<UserCreate />} />
        <Route path='/user/edit/:id' element={<UserEdit />} />

        <Route path='/bins' element={<Bin />} />
        <Route path='/bin/create' element={<BinCreate />} />
        <Route path='/bin/edit/:id' element={<BinEdit />} />

        <Route path='/storages' element={<Storage />} />
        <Route path='/storage/create' element={<StorageCreate />} />
        <Route path='/storage/edit/:id' element={<StorageEdit />} />

        <Route path='/subtypes' element={<Subtype />} />
        <Route path='/subtype/create' element={<SubtypeCreate />} />
        <Route path='/subtype/edit/:id' element={<SubtypeEdit />} />

        <Route path='/types' element={<Type />} />
        <Route path='/type/create' element={<TypeCreate />} />
        <Route path='/type/edit/:id' element={<TypeEdit />} />



      </Routes>

    </Router>
  );
}

export default App;
