import Axios from "../Axios";

const material = {
    read() {
        return Axios.get('/materials', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object) {
        return Axios.post('/materials', data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    }
}

export default material;
