import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Icon from '../../ui/Icon';
import { Link, useParams } from "react-router-dom";
import resources from '../../api';
import Button from "../../ui/Button";

export default function Edit() {

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})
    const { id } = useParams();
    // const history = useHistory()

    async function read() {
        let res = await resources.subtype.readOne(id)
        if (res) {
            setData(res.data)
        }
    }
    async function update(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let res = await resources.subtype.update(id, object)
        if (res) {
            setLoader(false)
            window.location.replace("/subtypes")
        }
        setLoader(false)
    }

    useEffect(() => {
        read()
    }, [])


    return (
        <div>
            <Header />
            <div className='container'>
                <div className="row">
                    <div className="col">
                        <h3 className='mt-3'>Subtype</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page"><Link
                                        className="text-muted" to="/subtypes">Subtype</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">update subtype</li>
                                </ol>
                            </nav>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <div className='card'>

                                    <div className='card-body'>
                                        <form onSubmit={update} >
                                         
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Name<span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="text" required className="form-control" id="exampleInputEmail1"
                                                    name="name" defaultValue={data?.name} />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Name <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                    name="description" defaultValue={data?.description} />
                                            </div>
                                            <Button type="submit" onLoad={loader} className="btn btn-app-default w-100">Save</Button>
                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
