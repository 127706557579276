import React from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';

export default function index() {
    return (
        <div>
            <Header />
            <div className='container'>
                <div className="row">
                    <div className="col">
                        <h3 className='mt-3'>Companies</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">

                                    <li className="breadcrumb-item active" aria-current="page">Companies</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default " to="/company/create"><Icon name="add" color="white" /> New</Link>
                        </div>

                        <DataTable column={[
                            {
                                label: "Cover ", field: "picture", renderStyle: (data) => {
                                    return <img src={config.server + "/companies/" + data.id + "/img"} alt={data.name} width="50" />
                                }
                            },
                            { label: "ID ", field: "id" },
                            { label: "Name", field: "name" }
                        ]}

                            element={"company"}
                            resource={resources.company}
                            action={(data) => {

                            }}
                            deleteItem
                            update
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
